<template>
	<div id="app">
    <Home></Home>
	</div>
</template>

<script>
import Home from '@/view/home/home'
	export default {
		name: 'App',
		components: {
      Home
		},
		data() {
			return {

			}
		},
		created() {

		},
		mounted() {
		},
		methods: {

		}
	}
</script>

<style>
	@media only screen and (min-width: 1280px){
	   html{
	     /*font-size: 46px;*/
	   }
	 }
	 @media only screen and (max-width: 1280px) and (min-width: 960px){
	   html{
	     /*font-size: 26px;*/
	   }
	 }
	 @media only screen and (max-width: 960px){
	   html{
	     /*font-size: 10px;*/
	   }
	 }
  *{
    margin: 0;
    padding: 0;
  }
  html{

    background: #f6f6f6;
  }

</style>