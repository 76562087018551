import Vue from 'vue'
import App from './App.vue'
import {mixin} from './mixin.js'
import router from "@/router";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 全局挂载
Vue.use(VueAwesomeSwiper)
router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title;
	}
	next();
});
Vue.mixin(mixin)
Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
